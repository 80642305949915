<template>
  <EditIngestion />
</template>

<script>
import EditIngestion from '@/components/EditIngestion';

export default {
  components: {
    EditIngestion
  }
};
</script>
